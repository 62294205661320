import styled from '@emotion/styled'
import { memo } from 'react'
import { AiFillSetting } from 'react-icons/ai'
import { FaTools } from 'react-icons/fa'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

export const UndevelopedView = memo<ReactProps>(function UndevelopedView() {
  return (
    <styleds.container>
      <styleds.columnContent></styleds.columnContent>
      <styleds.columnContent></styleds.columnContent>
      <styleds.columnContent></styleds.columnContent>
      <styleds.columnContent>
        <styleds.cardContent height={'30px'} />
        <styleds.cardContent height={'160px'} />
        <styleds.cardContent height={'calc(100% - 200px)'} />
      </styleds.columnContent>
      <styleds.columnContent>
        <styleds.cardContent height={'100%'}>
          <p>系統校正中...</p>
          <FaTools size={24} />
        </styleds.cardContent>
      </styleds.columnContent>
      <styleds.columnContent>
        <styleds.cardContent height={'180px'} />
        <styleds.cardContent height={'180px'} />
        <styleds.cardContent height={'calc(100% - 380px)'} />
      </styleds.columnContent>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_all_center};
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 336px 1fr 336px;
    grid-template-rows: 48px 1fr;
    background-color: #ffffff;
    font-size: 24px;
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    padding: 4px;
    gap: 8px;
  `,
  columnContent: styled.div`
    ${fill_vertical_all_center};
    width: 100%;
    height: 100%;
    background-color: #eaeaea;
    border-radius: 5px;
    gap: 8px;
    padding: 8px;
  `,
  cardContent: styled.div<{ height: string }>`
    ${fill_vertical_all_center};
    width: 100%;
    height: ${props => props.height};
    background-color: #dedede;
    border-radius: 4px;
  `,
}
